import { useState } from "react";
import Dialog from "../../components/Dialog";
import recruiter1 from "../../assets/avatars/recruiter_1.webp";
import recruiter2 from "../../assets/avatars/recruiter_2.webp";
import recruiter3 from "../../assets/avatars/recruiter_3.webp";
import recruiter4 from "../../assets/avatars/recruiter_4.webp";
import recruiter5 from "../../assets/avatars/recruiter_5.webp";
import recruiter6 from "../../assets/avatars/recruiter_6.webp";
import recruiter7 from "../../assets/avatars/recruiter_7.webp";
import tutorialVideo from "../../assets/video/2mm_tutorial.mp4";
import { Profile } from "../../services/profile/profileModel";
import { RecordType, RecorderConfig } from "./RecordPage";
import { Capsule } from "../../services/capsule/capsuleModel";
// TODO : this video is actually a copy of the tutorial video => to be replaced by the real one
import tutorialCapsuleVideo from "../../assets/video/2mm_tutorial_pro.mp4";
import { useTranslation } from "react-i18next";

enum Step {
  Advices,
  Interlocutor,
  Notes,
}

const INTERLOCUTORS = [
  recruiter1,
  recruiter2,
  recruiter3,
  recruiter4,
  recruiter5,
  recruiter6,
  recruiter7,
];

export const InterlocutorChoiceComponent = ({
  onChoice,
  onNext,
  onPrevious,
}: {
  onChoice: (interlocutor: string | null) => void;
  onNext?: () => void;
  onPrevious?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="popup_head">
        <h2 className="popup_title">
          {t("record:config.CHOOSE_AVATAR_TITLE")}
        </h2>
        <p className="body">{t("record:config.CHOOSE_AVATAR_DESCRIPTION")}</p>
      </div>

      <div className="popup_body">
        <div className="lrow --center--h">
          <button className="btn--choose-avatar" onClick={() => onChoice(null)}>
            {t("record:config.NO_AVATAR")}
          </button>
          {INTERLOCUTORS.map((src) => (
            <button
              className="btn--choose-avatar"
              key={src}
              onClick={() => onChoice(src)}
            >
              <img src={src} />
            </button>
          ))}
        </div>
      </div>
      {onNext && (
        <div className="popup_footer">
          {onPrevious && (
            <button className="btn--grey" onClick={onPrevious}>
              {t("record:config.PREVIOUS_BUTTON")}
            </button>
          )}
          <button className="btn" onClick={onNext}>
            {t("record:config.CONTINUE_BUTTON")}
          </button>
        </div>
      )}
    </div>
  );
};

const RecorderConfigurationComponent = ({
  type,
  capsule,
  profile,
  onClose,
}: {
  type: RecordType;
  capsule?: Capsule;
  profile?: Profile;
  onClose(values: RecorderConfig): void;
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(
    (type === "profile"
      ? profile!.lang.toUpperCase()
      : type === "capsule"
        ? capsule!.lang.toUpperCase()
        : "") === "FR"
      ? Step.Advices
      : Step.Interlocutor,
  );

  const [interlocutor, setInterlocutor] = useState<null | string>(null);
  const [notes, setNotes] = useState("");

  return (
    <Dialog
      className="--branded"
      onClose={() =>
        onClose({
          interlocutor,
          notes: "",
          initialized: true,
          showNotes: false,
        })
      }
    >
      <div>
        {step === Step.Advices && (
          <div>
            <div className="popup_head">
              <h2 className="popup_title">
                {t("record:config.TIPS_BEFORE_STARTING_TITLE")}
              </h2>
            </div>
            <div className="popup_body">
              {/* <div style={{ padding: "54.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/925096048?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  title="2 Minutes Max - Tutoriel"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script> */}

              <video
                className="videoplayer"
                playsInline
                src={type === "profile" ? tutorialVideo : tutorialCapsuleVideo}
                controls
                onContextMenu={(e) => e.preventDefault()}
              />
              <div className="cblock">
                {/* TODO: add style */}
                <div className="cblock info">
                  {t("record:config.TIPS_DETAILS")}
                  <ul>
                    <li>{t("record:config.TIPS_TRY_AGAIN")}</li>
                    <li>
                      {profile
                        ? t("record:config.TIPS_VIDEO_PRIVACY")
                        : t("record:config.TIPS_EDIT_VIDEO")}
                    </li>
                    <li>{t("record:config.TIPS_DISABLE_ANYTIME")}</li>
                  </ul>
                </div>
              </div>
              <div className="cblock">
                <div className="--txt--center">
                  <a
                    className="link --light --underlined"
                    href={
                      type === "profile"
                        ? "https://www.2minutesmax.com/conseils-candidats/"
                        : "https://www.2minutesmax.com/entreprises/"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("record:config.TIPS_VIDEO_LINK")}
                  </a>
                </div>
              </div>
            </div>
            <div className="popup_footer">
              <button
                className="btn"
                onClick={() => setStep(Step.Interlocutor)}
              >
                {t("record:config.CONTINUE_BUTTON")}
              </button>
            </div>
          </div>
        )}
        {step === Step.Interlocutor && (
          <InterlocutorChoiceComponent
            onChoice={(choice) => {
              setInterlocutor(choice);
              setStep(Step.Notes);
            }}
            onNext={() => setStep(Step.Notes)}
            onPrevious={() => setStep(Step.Advices)}
          />
        )}
        {step === Step.Notes && (
          <div>
            <div className="popup_head">
              <h2 className="popup_title">
                {t("record:config.NOTES_HELP_TITLE")}
              </h2>
            </div>
            <div className="popup_body">
              <textarea
                className="textarea"
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
              />
            </div>

            <div className="popup_footer">
              <button
                className="btn--grey"
                onClick={() => setStep(Step.Interlocutor)}
              >
                {t("record:config.PREVIOUS_BUTTON")}
              </button>
              <button
                className="btn"
                onClick={() =>
                  onClose({
                    interlocutor,
                    notes,
                    initialized: true,
                    showNotes: notes.length > 0,
                  })
                }
              >
                {t("record:config.READY_BUTTON")}
              </button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default RecorderConfigurationComponent;
