/*
Context:
  getUser,
  checkUserValidity,
  updateUser,
  et toutes méthodes de manipulation d'un user existant en DB
*/

import { toastsWithIntl } from "../toastService";
import { Profile } from "./profileModel";
import {
  deleteProfileRequest,
  generateProfileCoverLetterRequest,
  getProfileCoverLetterRequest,
  updateProfileRequest,
} from "./profileApi";
import countries from "../../internationalization/countries/en.json";
import langs from "../../internationalization/languages/en.json";
import { t } from "i18next";
import { compareString } from "../../react-helpers/array";
import { useTranslation } from "react-i18next";

interface ProfileService {
  updateProfileById: (
    profileId: Profile["id"],
    profile: Partial<Profile>,
  ) => Promise<Profile>;
  deleteProfileById: (profileId: Profile["id"]) => Promise<void>;
  disableProfileById: (profileId: Profile["id"]) => Promise<void>;
  enableProfileById: (profileId: Profile["id"]) => Promise<void>;
  generateProfileCoverLetter: (
    profileId: Profile["id"],
    qrCode: string,
  ) => Promise<string>;
  getProfileCoverLetter: (profileId: Profile["id"]) => Promise<string>;
}
export const profileService = () => {
  const { toastSuccess, toastError } = toastsWithIntl(["candidate"]);

  const updateProfileById: ProfileService["updateProfileById"] = (
    profileId,
    profile,
  ) => {
    return updateProfileRequest(profileId, profile).then(
      (updatedProfile) => {
        toastSuccess("candidate:update-profile.SUCCESS");
        return Promise.resolve(updatedProfile);
      },
      (err) => {
        toastError("candidate:update-profile.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const deleteProfileById: ProfileService["deleteProfileById"] = (
    profileId,
  ) => {
    return deleteProfileRequest(profileId).then(
      () => {
        toastSuccess("candidate:delete-profile.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("candidate:delete-profile.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const disableProfileById: ProfileService["disableProfileById"] = (
    profileId,
  ) => {
    return updateProfileRequest(profileId, {
      disabledDate: new Date().toISOString(),
    }).then(
      () => {
        toastSuccess("candidate:disable-profile.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("candidate:disable-profile.ERROR");
        return Promise.reject(err);
      },
    );
  };
  const enableProfileById: ProfileService["enableProfileById"] = (
    profileId,
  ) => {
    return updateProfileRequest(profileId, {
      disabledDate: null,
    }).then(
      () => {
        toastSuccess("candidate:enable-profile.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("candidate:enable-profile.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const generateProfileCoverLetter: ProfileService["generateProfileCoverLetter"] =
    (profileId, qrCode) => {
      return generateProfileCoverLetterRequest(profileId, qrCode).then(
        (coverLetter) => {
          return Promise.resolve(coverLetter);
        },
        (err) => {
          toastError("candidate:cover-letter.ERROR");
          return Promise.reject(err);
        },
      );
    };

  const getProfileCoverLetter: ProfileService["getProfileCoverLetter"] = (
    profileId,
  ) => {
    return getProfileCoverLetterRequest(profileId).then(
      (coverLetter) => {
        return Promise.resolve(coverLetter);
      },
      (err) => {
        toastError("candidate:cover-letter.ERROR");
        return Promise.reject(err);
      },
    );
  };

  return {
    updateProfileById,
    deleteProfileById,
    disableProfileById,
    enableProfileById,
    generateProfileCoverLetter,
    getProfileCoverLetter,
  };
};

export const COUNTRIES = Object.keys(countries)
  .map((k) => [k, t(`countries:${k}`)] as [string, string])
  .sort((a, b) => compareString(a[1], b[1]));
export const useCountries = () => {
  const { t: translateHooked } = useTranslation(["countries"]);
  return Object.keys(countries)
    .map(
      (k) => [k, translateHooked(`countries:${k}` as any)] as [string, string],
    )
    .sort((a, b) => compareString(a[1], b[1]));
};
export const LANGS = Object.keys(langs)
  .map((k) => [k, t(`languages:${k}`)] as [string, string])
  .sort((a, b) => compareString(a[1], b[1]));
export const useLangs = () => {
  const { t: translateHooked } = useTranslation(["languages"]);
  return Object.keys(langs)
    .map(
      (k) => [k, translateHooked(`languages:${k}` as any)] as [string, string],
    )
    .sort((a, b) => compareString(a[1], b[1]));
};
