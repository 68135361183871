import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createRef, useCallback, useEffect, useState } from "react";
import { MAX_UNCOMPRESSED_SIZE_MB, RecordType } from "./RecordPage";
import IconValidate from "../../assets/icons/icon-thumb-up.svg";
import { loggerBuilder } from "../../services/logger";
import { Profile } from "../../services/profile/profileModel";
import { useRecorder } from "../../services/recorderService";
import VideoPlayer from "../../components/VideoPlayer";
import SubmitButton from "../../components/SubmitButton";
import { thumbnailOfVideo } from "../../react-helpers/blob";
import { createProfileUploadLinkRequest } from "../../services/profile/profileApi";
import { Capsule } from "../../services/capsule/capsuleModel";
import { createCapsuleUploadLinkRequest } from "../../services/capsule/capsuleApi";
import { cx } from "../../react-helpers/css";

const MAX_VIDEO_COUNT = 4;
const logger = loggerBuilder("review-component");

const ReviewComponent = ({
  type,
  videos,
  profileId,
  capsuleId,
  setShowReview,
  clearVideos,
}: {
  type: RecordType;
  videos: [string, Blob][];
  profileId?: Profile["id"];
  capsuleId?: Capsule["id"];
  setShowReview(value: boolean): void;
  clearVideos(): void;
}) => {
  const { onUpload, onCompress, ffmpegPercent, stopRecorder } = useRecorder()!;
  const { t } = useTranslation(["record"]);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [choosenVideo, setChoosenVideo] = useState<string | null>(null);
  const [failedToUploadMeta, setFailedToUploadMeta] = useState<{
    thumbnail: string;
    duration?: number;
  } | null>(null);

  useEffect(() => {
    stopRecorder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitVideo = useCallback(
    async (video: Blob, videoRef?: HTMLVideoElement | null) => {
      let thumbnail: string;
      if (videoRef) {
        logger.info("Generating thumbnail");
        thumbnail = await thumbnailOfVideo(videoRef);
      } else {
        thumbnail = failedToUploadMeta!.thumbnail;
      }
      const duration = videoRef?.duration ?? failedToUploadMeta?.duration;

      setSubmitting(true);
      try {
        logger.info("Getting upload link");
        const createUploadLink = async () => {
          if (type === "profile")
            return (await createProfileUploadLinkRequest(profileId!, thumbnail))
              .uploadLink;
          else if (type === "capsule")
            return (await createCapsuleUploadLinkRequest(capsuleId!, thumbnail))
              .uploadLink;
          else throw new Error("Invalid record type");
        };

        logger.info("Starting upload process");
        await toast.promise(onUpload(createUploadLink, video, duration), {
          loading: t("record:upload.SAVING"),
          success: t("record:upload.SUCCESS"),
          error: t("record:upload.ERROR"),
        });
      } catch (e) {
        setFailedToUploadMeta({ thumbnail, duration });
        logger.error(e);
        setSubmitting(false);
        return;
      }

      return type === "profile"
        ? navigate(`/candidate/profiles/${profileId}`)
        : type === "capsule"
          ? navigate(`/organization/capsules/${capsuleId}`)
          : navigate("/");
    },
    [capsuleId, failedToUploadMeta, navigate, onUpload, profileId, t, type],
  );

  return (
    <div
      className={cx([
        "layout_content",
        type === "profile" && "--bg--candidate",
        type === "capsule" && "--bg--organization",
      ])}
    >
      <div className="page-content container --wrap--m">
        <div className="page_head">
          <h1 className="page_title --txt--center">
            {t("record:review.CHOOSE_TAKE")}
          </h1>
        </div>
        {failedToUploadMeta && choosenVideo && (
          <div className="info --warning cblock">
            {t("record:upload.FAILED_INFO")}
            <br />
            <SubmitButton
              type="button"
              className="btn--2"
              onClick={() => {
                const video = videos.find(([id]) => id === choosenVideo)![1];
                return onSubmitVideo(video);
              }}
            >
              {t("record:upload.RETRY")}
            </SubmitButton>
          </div>
        )}
        <div className="lblock--l grid-2">
          {videos.map(([id, video]) => {
            const ref = createRef<HTMLVideoElement>();
            return (
              <div key={id}>
                <div className="video-preview">
                  <VideoPlayer video={video} ref={ref} />
                  {choosenVideo === id &&
                    ffmpegPercent !== null &&
                    ffmpegPercent < 100 && (
                      <div className="encoding-status">
                        {t("record:review.ENCODING", {
                          percent: ffmpegPercent,
                        })}
                      </div>
                    )}

                  <SubmitButton
                    type="button"
                    className="btn-validate-video"
                    disabled={submitting}
                    onClick={async () => {
                      setChoosenVideo(id);
                      const videoRef = ref.current;

                      setSubmitting(true);
                      try {
                        logger.info("Starting compress process");
                        await onCompress(video, ref.current?.duration);
                      } catch (e) {
                        logger.error(e);
                        toast.error(t("record:review.COMPRESSION_FAILED"));
                        setSubmitting(false);
                        return;
                      }
                      return onSubmitVideo(video, videoRef);
                    }}
                  >
                    <img src={IconValidate} className="icon" />
                    {t("record:review.VALIDATE_TAKE")}
                  </SubmitButton>
                </div>
                {video.size > MAX_UNCOMPRESSED_SIZE_MB * 1024 * 1024 && (
                  <div className="body--30 --strong">
                    {t("record:review.COMPRESSION_NOTICE", {
                      size: (video.size / 1024 / 1024).toFixed(2),
                    })}
                  </div>
                )}
              </div>
            );
          })}
          {videos.length < MAX_VIDEO_COUNT && (
            <button
              className="card-new --video"
              onClick={() => setShowReview(false)}
            >
              <div className="card_image_container">
                <canvas className="card_image_bg" width="16" height="9" />
              </div>
              <div className="card_body">{t("record:review.RETRY_TAKE")}</div>
            </button>
          )}
        </div>
        <div className="lblock--l --txt--center">
          <button
            className="btn--2"
            onClick={() => {
              clearVideos();
              setShowReview(false);
            }}
          >
            {t("record:review.RESTART_ALL")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewComponent;
