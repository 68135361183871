import { useTranslation } from "react-i18next";

const SubsciptionSection = () => {
  const { t } = useTranslation();
  return (
    <div className="lblock--l">
      <h2 className="section_title">
        {t("accounts:subscription.SECTION_TITLE")}
      </h2>
      <div className="lblock --txt--center">
        <div className="card_body">
          <a
            href="https://www.2minutesmax.com/order/"
            target="_blank"
            rel="noreferrer"
            className="btn--3"
          >
            {t("accounts:subscription.buttons.COMMAND")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SubsciptionSection;
