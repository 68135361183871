import { Link, useLoaderData } from "react-router-dom";
import { isAfter, parseISO } from "date-fns";
import { Organization } from "../../services/organization/organizationModel";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const OrganizationDashboardPage = () => {
  const { t } = useTranslation();
  const { organization } = useLoaderData() as { organization: Organization };

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap">
        <h1 className="page_title">
          {t("organization:dashboard.WELCOME_RECRUITER_DASHBOARD")}
        </h1>

        {!organization.validationDate && (
          <div className="lblock info --warning">
            {t("organization:dashboard.ACCOUNT_PENDING_VALIDATION")}
          </div>
        )}
        {organization.subscriptionEndDate &&
          isAfter(new Date(), parseISO(organization.subscriptionEndDate)) && (
            <div className="lblock info --warning">
              {t("organization:dashboard.SUBSCRIPTION_EXPIRED")}{" "}
              <Link className="link --underlined" to="/contact">
                {t("organization:dashboard.CONTACT_US_TO_RENEW")}
              </Link>
            </div>
          )}

        <div className="organisation-dashboard_tiles">
          <Link to="candidates" className="dashboard_tile">
            <canvas className="tile_img --candidates" />
            <h2 className="tile_title">
              {t("organization:dashboard.MY_CANDIDATES")}
            </h2>
          </Link>
          <Link to="capsules" className="dashboard_tile">
            <canvas className="tile_img --capsules" />
            <h2 className="tile_title">
              {t("organization:dashboard.MY_JOB_POSTINGS")}
            </h2>
          </Link>
          {organization.validationDate ? (
            <a
              href="https://www.2minutesmax.com/entreprises/"
              target="_blank"
              rel="noreferrer"
              className="dashboard_tile --guide"
            >
              <canvas width="2" height="3" />
            </a>
          ) : (
            <>
              <div
                className="dashboard_tile --guide --disabled"
                data-tooltip-id="tile-tooltip"
                data-tooltip-content={t("organization:dashboard.tips.TILE")}
              >
                <canvas width="2" height="3" />
              </div>
              <Tooltip id="tile-tooltip" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationDashboardPage;
